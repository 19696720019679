<template>
  <div>
    <b-button
      type="submit"
      variant="primary"
      :to="`/tracker/detail/${uuid}`"
      size="md"
      class="mb-1"
    >
      <feather-icon
        icon="ArrowLeftIcon"
        class="mr-50"
      />
      <span class="align-middle">{{$t('Labels.Back')}}</span>
    </b-button>
    <b-row>
      <b-col class="my-2">
        <b-button variant="primary" :to="`/contacts/${uuid}/add`" class="float-right">
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span  class="align-middle">{{$t("Labels.Add")}}</span>
        </b-button>
      </b-col>
    </b-row>
    <b-row align-v="center" v-if="$store.state.session.AppActiveUser().userRole !== 'TP'">
      <b-col sm="auto" cols="3">
        <b-form-select
          name="origin"
          :options="trackers"
          v-model="originTracker"
          value-field="uuid"
          text-field="name"
          :clearable="false"
        >
          <template #first>
            <b-form-select-option :value="null" disabled>{{$t('Labels.OriginDevice')}}</b-form-select-option>
          </template>
        </b-form-select>
      </b-col>
      <b-col sm="auto" cols="3">
        <b-button @click="doImport()" variant="primary" >{{$t('Labels.Import')}}</b-button>
      </b-col>
    </b-row>
    <div id="data-list-list-view" class="data-list-container mt-2 px-">
      <table id="table-contacts">
        <draggable
          v-model="response"
          tag="tbody"
          @end="dragEnd"
          draggable=".item"
        >
          <tr class="item-row item" v-for="(item, index) in response" :key="index" :data-uuid="item.uuid">
            <td>
              <div class="px-1">
                <feather-icon icon="MoveIcon" />
              </div>
            <td>
            <td>
              <listItem
                :value="item.name"
                :description="$t('Labels.Name')"
                img="contact"
              />
            </td>
            <td>
              <listItem
                :value="getRelationshipLabel(item.relationship)"
                :description="$t('Labels.Relationship')"
                img="relationship"
              />
            </td>
            <td>
              <listItem
                :value="item.phone"
                :description="$t('Labels.Phone')"
                img="phone"
              />
            </td>
            <td>
              <listItem
                :value="item.email"
                :description="$t('Email')"
                img="email"
              />
            </td>
            <td>
              <b-button variant="primary" class="btn-icon rounded-circle btn-option mt-1 mb-1" :to="`/contacts/detail/${uuid}/${item.uuid}`" :title="$t('Labels.Detail')" v-b-tooltip.hover="{ variant: 'primary', placement: 'top' }">
                <feather-icon icon="EyeIcon" :width="18" :height="18"/>
              </b-button>
              <b-button variant="primary" class="btn-icon rounded-circle btn-option mt-1 mb-1" :to="`/contacts/edit/${uuid}/${item.uuid}`" :title="$t('Labels.Edit')" v-b-tooltip.hover="{ variant: 'primary', placement: 'top' }">
                <feather-icon icon="EditIcon" :width="18" :height="18"/>
              </b-button>
              <b-button variant="danger" class="btn-icon rounded-circle mt-1 mb-1" @click="confirmDelete(item.uuid)" :title="$t('Labels.Delete')" v-b-tooltip.hover="{ variant: 'danger', placement: 'top' }">
                <feather-icon icon="TrashIcon" :width="18" :height="18"/>
              </b-button>
            </td>
          </tr>
        </draggable>
      </table>
    </div>
    <div v-if="response.length == 0" class="noData">{{$t('Messages.NoData')}}</div>
  </div>
</template>

<script>
import request from '@/libs/request/index'
import listItem from '@core/spore-components/listItem/listItem.vue'
import {
  BButton,
  BRow,
  BCol,
  BFormSelect,
  BFormSelectOption
} from 'bootstrap-vue'
import draggable from 'vuedraggable'

const DATA = {
  code: 200,
  data: {
    count: 2,
    rows: []
  },
  msg: 'Ok'
}

export default {
  components: {
    listItem,
    BButton,
    BRow,
    BCol,
    draggable,
    BFormSelect,
    BFormSelectOption
  },
  data () {
    return {
      response: [],
      DATA,
      uuid: this.$route.params.uuid,
      trackers: [],
      originTracker: null
    }
  },

  methods: {
    async getResponse () {
      // this.response = DATA.data.rows
      const params = {
        url: `tracker_contacts/myList/${this.uuid}`,
        method: 'GET'
      }
      await request(params).then(response => {
        this.response = response.data.data
      }).catch(() => {
        this.response = []
      })
    },
    async getTrackers () {
      const params = {
        url: 'tracker',
        method: 'GET',
        params: {
          limit: 100,
          offset: 0,
          byColumn: 'updated_at',
          byOrder: 'ASC'
        }
      }
      await request(params).then(response => {
        this.trackers = response.data.data.rows
      }).catch(() => {
        this.trackers = []
      })
    },
    getRelationshipLabel (value) {
      const relationship = [
        this.$t('Labels.Parents'),
        this.$t('Labels.Childrens'),
        this.$t('Labels.BroSis'),
        this.$t('Labels.HusWife'),
        this.$t('Labels.GrandParents'),
        this.$t('Labels.Grandchildren'),
        this.$t('Labels.Cousin'),
        this.$t('Labels.Nephews'),
        this.$t('Labels.Uncles'),
        this.$t('Labels.SonInLaw'),
        this.$t('Labels.ParentsInLaw'),
        this.$t('Labels.BroInLaw'),
        this.$t('Labels.NoParentship'),
        this.$t('Labels.DistantFamily'),
        this.$t('Labels.None')
      ]
      return relationship[parseInt(value) - 1] || ''
    },
    async dragEnd (e) {
      const uuids = this.response.map(item => { return item.uuid })
      const params = {
        url: `tracker_contacts/${this.uuid}/priority`,
        method: 'PUT',
        customMessages: true,
        params: {
          contacts: uuids.join(',')
        }
      }
      await request(params)
    },
    confirmDelete (uuid) {
      this.$swal({
        title: this.$t('Messages.ConfirmDeleteContact'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Messages.Yes'),
        cancelButtonText: this.$t('Messages.Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(async result => {
        if (result.value) {
          const params = {
            url: `tracker_contacts/delete/${uuid}`,
            method: 'DELETE'
          }
          await request(params).then(response => {
            this.getResponse()
          })
        }
      })
    },
    async doImport () {
      const params = {
        url: 'tracker_contacts/import',
        method: 'POST',
        params: {
          tracker_origen_uuid: this.originTracker,
          tracker_destino_uuid: this.uuid
        }
      }
      await request(params).then(response => {
        this.getResponse()
      })
    }
  },

  created () {
    this.getResponse()
    this.getTrackers()
  },

  watch: {
    currentx (val) {
      this.filters.offset = (val - 1) * this.filters.limit
      this.getResponse()
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "@/assets/scss/pages/ListContacts.scss";
</style>
